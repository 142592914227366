import { gsap } from "gsap";



export class Slider {
    constructor(el) {
        this.el = el;

        this.tl = gsap.timeline({paused: true},{
            defaults: {
            ease: 'power2.inOut',
            duration: .6    
        }})
        .to(this.el, {

            visibility: 'visible',
           
    
          } )
          .to('.switch', {
           opacity: 1,
           startAt: {yPercent: 101},
           yPercent: 0,
            
          
          })
          
          .to(this.el, {
           
           xPercent: 100
           
            })
           

          
        .to(this.el, {
           
            visibility : 'hidden',
           
           
           
        } )
       
       
         
    }

    in() {
      

        this.tl.restart();
      }
    
    
}