

import barba from '@barba/core';
import { gsap } from "gsap";
import { Slider } from './slider'
import { preloadImages } from './utils';
import Lenis from '@studio-freight/lenis';
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { ScrollTrigger } from 'gsap/ScrollTrigger';


gsap.registerPlugin(ScrollToPlugin);
gsap.registerPlugin(ScrollTrigger);


const buttonEnter = ()=> {
  gsap.to('.button-enter', {

    visibility: 'hidden',
    scrollTrigger: {
      trigger: '#preface',
      start: '80% center',
      end: 'top top',
      once: true      
    }
  })
  
  
const btn = document.querySelector('.button-enter');
    btn.addEventListener('click', () => {
    gsap.to(window, {duration: 1, ease: 
      'Power2.easeOut', scrollTo: '#pier'}); 
  })
}





  barba.hooks.after((data) => {

        if(data.next.namespace === 'Home') {
          buttonEnter();
        }
  });

  

const slider = new Slider(document.querySelector('.slide'));


barba.init({
  transitions: [{
    name: 'default-transition',

    once(data) {
      buttonEnter();
    },

    leave(data) {
        slider.in();
      },
    enter(data) {
       gsap.to('.switch', {
        textContent: data.next.namespace,
      })
       gsap.to(window, {duration: 0, scrollTo: 0});
   

    
}
  }]
}); 




preloadImages('.item__img').then(() => {

  document.body.classList.remove('loading');

  const lenis = new Lenis({
    lerp: 0.1,
    smooth: true,
    direction: 'vertical',
  })

  function raf() {
    lenis.raf()
    requestAnimationFrame(raf)
  }
  
  requestAnimationFrame(raf)

});






        

